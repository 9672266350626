import { classNames } from "@/shared/lib/utils/classNames"
import React, { useEffect } from "react"
import { CSSTransition } from "react-transition-group"

import cls from "./Modal.module.scss"

const Modal = ({ children, isOpen, setOpen }) => {
    useEffect(() => {
        const handleEscKeyPress = (e) => {
            if (e.key === "Escape") {
                setOpen(false)
            }
        }

        document.addEventListener(
            "keydown",
            handleEscKeyPress
        )

        return () => {
            document.removeEventListener(
                "keydown",
                handleEscKeyPress
            )
        }
    }, [setOpen])

    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames='animation'
            unmountOnExit>
            <div className={classNames(cls.Modal, [])}>
                <div
                    onClick={() => setOpen(false)}
                    className={cls.background}
                    aria-hidden
                />
                <div className={cls.inner}>{children}</div>
            </div>
        </CSSTransition>
    )
}

export default Modal
